import React, { Component } from 'react'
import SwiperSlider from '../../components/SwiperSlidertest';

// import SliderImg1 from '../../images/popup-integrates/1.png';
// import SliderImg2 from '../../images/popup-integrates/2.png';
// import SliderImg3 from '../../images/popup-integrates/3.jpg';
// import SliderImg4 from '../../images/popup-integrates/4.png';
// import SliderImg5 from '../../images/popup-integrates/5.png';
// import SliderImg6 from '../../images/popup-integrates/6.png';
// import SliderImg7 from '../../images/popup-integrates/7.png';
// import SliderImg8 from '../../images/popup-integrates/8.png';
// import SliderImg9 from '../../images/popup-integrates/9.png';
// import SliderImg10 from '../../images/popup-integrates/10.png';
// import SliderImg11 from '../../images/popup-integrates/11.png';
// import SliderImg12 from '../../images/popup-integrates/12.png';
// import SliderImg13 from '../../images/popup-integrates/13.png';
// import SliderImg14 from '../../images/popup-integrates/14.png';
// import SliderImg15 from '../../images/popup-integrates/15.png';
// import SliderImg16 from '../../images/popup-integrates/16.png';
// import SliderImg17 from '../../images/popup-integrates/17.png';
// import SliderImg18 from '../../images/popup-integrates/18.png';
// import SliderImg19 from '../../images/popup-integrates/19.png';
// import SliderImg20 from '../../images/popup-integrates/20.png';
// import SliderImg21 from '../../images/popup-integrates/21.png';
// import SliderImg22 from '../../images/popup-integrates/22.jpg';
// import SliderImg23 from '../../images/popup-integrates/23.png';
// import SliderImg24 from '../../images/popup-integrates/24.png';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';





const HomePopupClients = ()=>{

    const data = useStaticQuery(graphql`
    query HomePopupClientsImageQuery {
        
        SliderImg1: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "1"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg2: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "2"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg3: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "3"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg4: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "4"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg5: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "5"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg6: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "6"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg7: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "7"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg8: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "8"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg9: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "9"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg10: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "10"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg11: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "11"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg12: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "12"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg13: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "13"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg14: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "14"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg15: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "15"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg16: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "16"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg17: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "17"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg18: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "18"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg19: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "19"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg20: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "20"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg21: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "21"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg22: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "22"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg23: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "23"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }

        SliderImg24: file(absolutePath: {regex: "/images/popup-integrates/"}, 
          name: {eq: "24"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }




      }
      
    
    `)


    const SliderImg1 = getImage(data.SliderImg1)
    const SliderImg2 = getImage(data.SliderImg2)
    const SliderImg3 = getImage(data.SliderImg3)
    const SliderImg4 = getImage(data.SliderImg4)
    const SliderImg5 = getImage(data.SliderImg5)
    const SliderImg6 = getImage(data.SliderImg6)
    const SliderImg7 = getImage(data.SliderImg7)
    const SliderImg8 = getImage(data.SliderImg8)
    const SliderImg9 = getImage(data.SliderImg9)
    const SliderImg10 = getImage(data.SliderImg10)
    const SliderImg11 = getImage(data.SliderImg11)
    const SliderImg12 = getImage(data.SliderImg12)
    const SliderImg13 = getImage(data.SliderImg13)
    const SliderImg14 = getImage(data.SliderImg14)
    const SliderImg15 = getImage(data.SliderImg15)
    const SliderImg16 = getImage(data.SliderImg16)
    const SliderImg17 = getImage(data.SliderImg17)
    const SliderImg18 = getImage(data.SliderImg18)
    const SliderImg19 = getImage(data.SliderImg19)
    const SliderImg20 = getImage(data.SliderImg20)
    const SliderImg21 = getImage(data.SliderImg21)
    const SliderImg22 = getImage(data.SliderImg22)
    const SliderImg23 = getImage(data.SliderImg23)
    const SliderImg24 = getImage(data.SliderImg24)



    const Slides1 = [
        {
            img: SliderImg1
        },
        {
            img: SliderImg2
        },
        {
            img: SliderImg3
        },
        {
            img: SliderImg4
        },
        {
            img: SliderImg5
        },
        {
            img: SliderImg6
        },
        {
            img: SliderImg7
        },
        {
            img: SliderImg8
        },
        {
            img: SliderImg9
        },
        {
            img: SliderImg10
        },
        {
            img: SliderImg11
        },
        {
            img: SliderImg12
        },
    ];

    const Slides2 = [
        {
            img: SliderImg13
        },
        {
            img: SliderImg14
        },
        {
            img: SliderImg15
        },
        {
            img: SliderImg16
        },
        {
            img: SliderImg17
        },
        {
            img: SliderImg18
        },
        {
            img: SliderImg19
        },
        {
            img: SliderImg20
        },
        {
            img: SliderImg21
        },
        {
            img: SliderImg22
        },
        {
            img: SliderImg23
        },
        {
            img: SliderImg24
        },
    ];


    return (
        <div className="home-popup-clients-section">
            
            <div className="container">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Integrate With  <span className="style-highlight"> Top 20+ ESPs
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> and Webhooks</h2>
                    <p>Collect your captured email leads in one place. Integrate with some of the best ESPs and webhooks in the industry <br/> seamlessly. And carry out multiple actions in autopilot.</p>
                </div>
                <div className="pt-3 position-relative">
                    <SwiperSlider slidesPerView={8} slides={Slides1} spaceBetween={0} slideDeley={3000} />
                    <SwiperSlider slidesPerView={8} slides={Slides2} spaceBetween={0} slideDeley={3000} dir="rtl" />
                </div>
            </div>
        </div>
    )
}

export default HomePopupClients
