import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import SwiperSlider from '../../components/SwiperSlidertest';
import Bg from '../../images/background-1.png';
import { FaArrowRight } from 'react-icons/fa';
// import SliderImg1 from '../../images/mike.png';
// import SliderImg2 from '../../images/jockbrocas.png';
// import SliderImg3 from '../../images/caseyjones.png';
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// const Slides = [
//     {
//         content: `
//             <div className="swiper-slide-contents">
//                 <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
//                 <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
//                 <b>Mike</b> - mrdzyn.com 
//                 </div>
//             </div>`,
//         img: SliderImg1
//     },
//     {
//         content: `
//             <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
//             </div>`,
//         img: SliderImg2
//     },
//     {
//         content: `
//             <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
//             <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
//         img: SliderImg3
//     },
// ]

// class HomeBanner extends Component{
//     constructor(props){
//         super(props);
//     }
//     render(){
//         return(
//             <div className="home-hero-container">
//                 <div className="container">
//                     <div className="text-container">
//                         <Row>
//                             <Col md="6">
//                                 <div className="hero-content wow fadeIn">
//                                     <h1>Build   <span className="style-highlight"> Smart Website Pop-ups 
//                                     <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
//                                      </span> <br/> Engage Visitors and Monetize Traffic Using Optinly Popup Maker</h1>
//                                     <p>Create website popups that your visitors would love to engage with. Boost sales and conversions with the best lead generation tool around – no design or development skills required.</p>       
//                                     <div>
//                                         <a className="btn btn-secondary" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
//                                             Grow Your Email List <FaArrowRight />
//                                         </a>
//                                         {/* <SignUpBar/> */}
//                                     </div>
//                                 </div>
//                             </Col>
//                             <Col md="6">
//                                 <div className="position-relative">
//                                     <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={10} slideDeley={5000} />
//                                     <img src={Bg} className="banner-bg" alt=""/>
//                                 </div>
        
//                             </Col>
//                         </Row>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }


const HomeBanner = ()=>{


    const data = useStaticQuery(graphql`
    
    query HomebanerImageQuery {
     
        BG:  file(absolutePath: {regex: "/images/"}, 
        name: {eq: "background-1"}) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, )
        }
      }

        mike:  file(absolutePath: {regex: "/images/"}, 
          name: {eq: "mike"}) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, )
          }
        }


        jockbrocas:  file(absolutePath: {regex: "/images/"}, 
        name: {eq: "jockbrocas"}) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, )
        }
      }

       
      caseyjones:  file(absolutePath: {regex: "/images/"}, 
      name: {eq: "caseyjones"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

      }


    `)

const BG = getImage(data.BG)
const SliderImg1 = getImage(data.mike)
const SliderImg2 = getImage(data.jockbrocas)
const SliderImg3 = getImage(data.caseyjones)


    const Slides = [
        {
            name:"Mike",
            content: `
                <div className="swiper-slide-contents">
                    <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                    <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                    <b>Mike</b> - mrdzyn.com 
                    </div>
                </div>`,
            img: SliderImg1,width:250,
        },
        {name:"JockBrocas",
            content: `
                <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
                </div>`,
            img: SliderImg2,width:250,
        },
        {name:"Casey Jones",
            content: `
                <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
                <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
            img: SliderImg3,width:250,
        },
    ]

    return(
        <div className="home-hero-container">
            
            
        <div className="container">
            <div className="text-container">
                <Row>
                    <Col md="6">
                        <div className="hero-content wow fadeIn">
                            <h1>Build   <span className="style-highlight"> Smart Website Pop-ups 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
                             </span> <br/> Engage Visitors and Monetize Traffic Using Optinly Popup Maker</h1>
                            <p>Create website popups that your visitors would love to engage with. Boost sales and conversions with the best lead generation tool around – no design or development skills required.</p>       
                            <div>
                                <a className="btn btn-secondary" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
                                    Grow Your Email List <FaArrowRight />
                                </a>
                                {/* <SignUpBar/> */}
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="position-relative">
                            <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={10} slideDeley={5000} />
                            <GatsbyImage image={BG}className="banner-bg" alt="banner-bg"/>
                            {/* <img src={Bg} className="banner-bg" alt=""/> */}
                        </div>

                    </Col>
                </Row>
            </div>
        </div>
    </div>
    )
}
export default HomeBanner;